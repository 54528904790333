<template>
  <Transition v-if="isClient"
              name="cart-slide"
              appear>
    <div v-if="visible"
         ref="root"
         class="bx-custom-sidebar sidebar">
      <template v-if="isClient">
        <slot />
      </template>
    </div>
  </Transition>
</template>

<script>
import clientOnly from '../../../../mixins/client-only'
export default {
  mixins: [clientOnly],
  props: {
    value: {
      type: Boolean
    }
  },
  data () {
    return {
      visible: false
    }
  },
  watch: {
    'value' (val) {
      setTimeout(() => this.toggle(val))
    }
  },
  methods: {
    clickOutside (e) {
      if (!this.$refs.root?.contains(e.target) && this.visible) {
        this.$emit('sidebarWasClosed', false)
        this.toggle(false)
      }
    },
    esc (e) {
      if (e.keyCode === 27) {
        this.$emit('sidebarWasClosed', false)
        return this.toggle(false)
      }
    },

    toggle (show) {
      if (show) {
        document.addEventListener('click', this.clickOutside)
        document.addEventListener('keydown', this.esc)
        this.visible = true
      } else {
        document.removeEventListener('click', this.clickOutside)
        document.removeEventListener('keydown', this.esc)
        this.visible = false
      }
    }
  }
}
</script>
